export const exitBtn = () => {
	return (
		<svg className="absolute top-40 right-[17rem]" width="48px" height="48px" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
			<path d="m215.8 131.86-13.918 13.953 134.19 134.19-125.54 125.54-6.9453 6.9727 13.918 13.918 6.9453-6.9727 125.54-125.52 134.21 134.21 13.918-13.918-134.21-134.21 125.55-125.54 6.9453-6.9805-13.918-13.918-6.9727 6.9805-125.54 125.54-127.25-127.25z" />
		</svg>
	);
};

export const bannerExitBtn = () => {
	return (
		<svg className="absolute right-32" width="32px" height="32px" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg">
			<path d="m215.8 131.86-13.918 13.953 134.19 134.19-125.54 125.54-6.9453 6.9727 13.918 13.918 6.9453-6.9727 125.54-125.52 134.21 134.21 13.918-13.918-134.21-134.21 125.55-125.54 6.9453-6.9805-13.918-13.918-6.9727 6.9805-125.54 125.54-127.25-127.25z" />
		</svg>
	);
};

export const menuArrow = () => {
	return (
		<svg width="20" height="16" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.1726 15.1816L29.4461 0.900628C29.6511 0.694776 29.6511 0.360481 29.4461 0.154444C29.2465 -0.0452137 28.9004 -0.0452137 28.7026 0.154444L14.7996 14.0627L0.899244 0.154444C0.799931 0.0559549 0.666134 0 0.526147 0C0.38616 0 0.252362 0.0559549 0.153049 0.154444C-0.0511538 0.360296 -0.0511538 0.694591 0.153875 0.900628L14.4264 15.1805C14.6281 15.3802 14.9731 15.3812 15.1728 15.1815L15.1726 15.1816Z"
				fill="black"
			/>
		</svg>
	);
};

export const dot = () => {
	return (
		<svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="3.5" cy="3.5" r="3.5" fill="black" />
		</svg>
	);
};

export const productColor = (color) => {
	return (
		<svg className="mr-2 relative productColor" width="15" height="15" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="3.5" cy="3.5" r="3.5" fill={color} />
		</svg>
	);
};

export const logo = () => {
	return (
		<svg width="45" height="45" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M45.0015 90H45.3015C57.3015 89.9 68.6015 85.2 77.0015 76.6C85.4015 68 90.1015 56.7 90.0015 44.7C89.8015 20.1 69.7015 0 45.0015 0C19.9015 0.2 -0.19852 20.5 0.00147967 45.3C0.20148 69.9 20.3015 90 45.0015 90ZM18.8015 42.1V39.3C18.8015 37.7 20.1015 36.4 21.7015 36.4C22.9015 36.4 23.9015 37.1 24.3015 38.1V32.4C24.3015 30.8 25.6015 29.5 27.2015 29.5C28.4015 29.5 29.4015 30.2 29.8015 31.2V26.7C29.8015 25.1 31.1015 23.8 32.7015 23.8C34.3015 23.8 35.6015 25.1 35.6015 26.7V42H54.2015V26.8C54.2015 25.2 55.5015 23.9 57.1015 23.9C58.7015 23.9 60.0015 25.2 60.0015 26.8V31.7C60.3015 30.5 61.5015 29.6 62.8015 29.6C64.4015 29.6 65.7015 30.9 65.7015 32.5V38C66.2015 37.1 67.2015 36.4 68.3015 36.4C69.9015 36.4 71.2015 37.7 71.2015 39.3V42.1C72.6015 42.3 73.6015 43.5 73.6015 45C73.6015 46.4 72.5015 47.6 71.2015 47.9V50.7C71.2015 52.3 69.9015 53.6 68.3015 53.6C67.2015 53.6 66.2015 53 65.7015 52V57.6C65.7015 59.2 64.4015 60.5 62.8015 60.5C61.5015 60.5 60.3015 59.6 60.0015 58.4V63.3C60.0015 64.9 58.7015 66.2 57.1015 66.2C55.5015 66.2 54.2015 64.9 54.2015 63.3V47.9H35.6015V63.2C35.6015 64.8 34.3015 66.1 32.7015 66.1C31.1015 66.1 29.8015 64.8 29.8015 63.2V58.7C29.3015 59.7 28.3015 60.4 27.2015 60.4C25.6015 60.4 24.3015 59.1 24.3015 57.5V51.8C23.8015 52.8 22.8015 53.5 21.7015 53.5C20.1015 53.5 18.8015 52.2 18.8015 50.6V47.8C17.4015 47.6 16.4015 46.4 16.4015 44.9C16.4015 43.4 17.4015 42.4 18.8015 42.1Z"
				fill="black"
			/>
		</svg>
	);
};
