import React from "react";

const CartIcon = () => {
	return (
		<>
			<div className="flex flex-row">
				<span>Cart</span>
				{/* <div className="counter bg-black text-white w-4 h-4 text-sm flex justify-center items-center">1</div> */}
			</div>
		</>
	);
};

export default CartIcon;
